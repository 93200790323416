import React from "react"

import spin_logo from "../assets/spin_logo.png"

export const SpinHeader = () => {
  return (
    <div className="spin-header">
      <a href="https://spin.app">
        <img src={spin_logo} className="spin-logo" alt="spin_logo" />
      </a>
    </div>
  )
}
