import { Trip, Vehicle } from "../types/types"

const baseUrl = process.env.REACT_APP_DEV
  ? "https://qa.spin.pm"
  : "https://web.spin.pm"

const commonHeaders = {
  "Content-Type": "application/json",
  "X-SPIN-REQUEST-SOURCE": "spin_rider_mobile_web",
}

type ErrorCallback = (error: string) => void

type TripDataResponse = {
  data: TripResponse
}

type TripResponse = {
  trip: Trip
}

type TripStart = {
  trip: Trip
  nfc_auth_token: string
}

type TripStartResponse = {
  data: TripStart
}

type TripStartSuccess = (trip: Trip, authToken: string) => void

type TripSuccess = (trip: Trip) => void

export type VehicleDataResponse = { data: { vehicle: Vehicle } }

type VehicleSuccess = (vehicle: Vehicle) => void

export const fetchVehicleData = async (
  vehicle_id: string,
  success: VehicleSuccess,
  errorCallback: ErrorCallback
) => {
  try {
    const result = await fetch(`${baseUrl}/api/nfc_v1/vehicles/${vehicle_id}`)
    if (result.status === 200) {
      const resultJson = (await result.json()) as VehicleDataResponse
      success(resultJson.data.vehicle)
    } else {
      errorCallback(`HTTP ${result.status}`)
    }
  } catch (error) {
    errorCallback(error)
  }
}

export const startTrip = async (
  email: string | undefined,
  vehicle_id: string,
  payment_token: string,
  success: TripStartSuccess,
  error: ErrorCallback
) => {
  const postData = {
    email,
    vehicle_id,
    payment_token,
  }

  try {
    const result = await fetch(`${baseUrl}/api/nfc_v1/trips/start.json`, {
      method: "POST",
      headers: commonHeaders,
      body: JSON.stringify(postData),
    })
    if (result.status === 201) {
      const resultJson = (await result.json()) as TripStartResponse
      success(resultJson.data.trip, resultJson.data.nfc_auth_token)
    } else {
      error(`HTTP ${result.status}`)
    }
  } catch (error) {
    error(error)
  }
}

export const endTrip = async (
  trip_id: string,
  authToken: string,
  success: TripSuccess,
  error: ErrorCallback
) => {
  try {
    const result = await fetch(`${baseUrl}/api/nfc_v1/trips/${trip_id}/stop.json`, {
      method: "POST",
      headers: {
        ...commonHeaders,
        Authorization: "Bearer " + authToken,
      },
    })
    if (result.status === 200) {
      const resultJson = (await result.json()) as TripDataResponse
      success(resultJson.data.trip)
    } else {
      error(`HTTP ${result.status}`)
    }
  } catch (error) {
    error(error)
  }
}

export const currentTrip = async (
  trip_id: string,
  authToken: string,
  success: TripSuccess,
  error: ErrorCallback
) => {
  try {
    const result = await fetch(`${baseUrl}/api/nfc_v1/trips/${trip_id}.json`, {
      headers: {
        ...commonHeaders,
        Authorization: "Bearer " + authToken,
      },
    })
    if (result.status === 200) {
      const resultJson = (await result.json()) as TripDataResponse
      success(resultJson.data.trip)
    } else {
      error(`HTTP ${result.status}`)
    }
  } catch (error) {
    error(error)
  }
}
