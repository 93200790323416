import React from "react"

import I18n from "../utilities/I18n"

export const DownloadAppButton = () => {
  return (
    <button className="btn-action" onClick={downloadApp}>
      {I18n.t("download_app")}
    </button>
  )
}

const downloadApp = () => {
  const { userAgent } = window.navigator
  const userOnAndroid = /Android/.test(userAgent)
  const userOnIphone = /iPhone/.test(userAgent)

  window.location.href = userOnIphone
    ? "https://itunes.apple.com/us/app/spin-bike-around-your-city/id1241808993?mt=8"
    : userOnAndroid
    ? "https://play.google.com/store/apps/details?id=pm.spin"
    : "https://spin.app"
}
