import React from "react"

import locked_scooter from "../assets/locked_scooter.png"

import { DownloadAppButton } from "./DownloadAppButton"

export const VehicleUnavailableView = () => {
  return (
    <div className="screen">
      <div className="body">
        <img src={locked_scooter} className="scooter-image" alt="locked_scooter" />
        <div className="unavailable">
          <div className="title">Scooter Unavailable</div>
          Please download the app so you can find another scooter.
        </div>
      </div>
      <div className="footer">
        <DownloadAppButton />
      </div>
    </div>
  )
}
