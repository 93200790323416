import React from "react"

import sad_face from "../assets/sad_face.png"

import { DownloadAppButton } from "../components/DownloadAppButton"

import I18n from "../utilities/I18n"

export const FailedTripView = () => {
  return (
    <div className="screen">
      <div className="body">
        <img src={sad_face} className="sad-face" alt="sad_face" />
        <div className="title">{I18n.t("sorry")}</div>
        <div className="text">{I18n.t("trouble_unlocking")}</div>
      </div>
      <DownloadAppButton />
    </div>
  )
}
