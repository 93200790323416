import React from "react"
import { Elements } from "react-stripe-elements"
import { PaymentRequestButton } from "../Payments"

type PaymentButtonProps = {
  amount: number
  lineItems: string[]
  canMakePayment: boolean | undefined
  setCanMakePayment: (canMakePayment: boolean) => void
  tokenSuccess: (token: string, email: string | undefined) => void
  tokenError: (error: string) => void
}

export const PaymentButton = (props: PaymentButtonProps) => {
  return (
    <div className="payment">
      {props.canMakePayment && (
        <>
          <p className="payment-text">
            By starting a trip, you are agreeing to{" "}
            <a href="https://www.spin.pm/terms/">
              Spin's Terms of Service.
            </a>
          </p>
          <p className="payment-text">Pay to Start Your Trip</p>
        </>
      )}
      <Elements>
        <PaymentRequestButton
          authAmountCents={props.amount}
          lineItems={props.lineItems}
          canMakePayment={props.canMakePayment}
          setCanMakePayment={props.setCanMakePayment}
          tokenSuccess={props.tokenSuccess}
          tokenError={props.tokenError}
        />
      </Elements>
    </div>
  )
}
