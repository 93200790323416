import I18n from "./I18n"
import { Rate } from "../types/types"

const locale = "en-US"
const currency = "USD"

export const formatMoney = (amount: number) => {
  if (amount < 0) {
    return (
      "(" +
      new Intl.NumberFormat(locale, { style: "currency", currency }).format(-amount / 100) +
      ")"
    )
  } else {
    return new Intl.NumberFormat(locale, { style: "currency", currency }).format(amount / 100)
  }
}

export const formatLineItemRates = (rate: Rate): string[] => {
  const effectiveRateDollars = formatMoney(rate.effective_rate)
  if (rate.base_rate > 0) {
    const baseRateDollars = formatMoney(rate.base_rate)
    return [
      I18n.t("rate_with_base.base", {
        base_rate: baseRateDollars,
      }),
      I18n.t("rate_with_base.period", {
        count: rate.rate_period,
        effective_rate: effectiveRateDollars,
      }),
    ]
  } else {
    return [
      I18n.t("rate_without_base", {
        count: rate.rate_period,
        effective_rate: effectiveRateDollars,
      }),
    ]
  }
}

export const formatRate = (rate: Rate) => {
  return formatLineItemRates(rate).join(". ")
}

export const formatMilliseconds = (milliseconds: number) => {
  const millisecondsInASecond = 1000
  const millisecondsInAMinute = 60 * millisecondsInASecond
  const millisecondsInAnHour = 60 * millisecondsInAMinute

  const hours = Math.floor(milliseconds / millisecondsInAnHour)
  let remainingMilliseconds = milliseconds - hours * millisecondsInAnHour
  const minutes = Math.floor(remainingMilliseconds / millisecondsInAMinute)
  remainingMilliseconds -= minutes * millisecondsInAMinute
  const seconds = Math.floor(remainingMilliseconds / millisecondsInASecond)

  return `${formatTwoDigits(hours)}:${formatTwoDigits(minutes)}:${formatTwoDigits(seconds)}`
}

const formatTwoDigits = (number: number) => {
  return ("0" + number).slice(-2)
}
