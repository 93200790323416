import React from "react"
import MDSpinner from "react-md-spinner"

import scooter from "../assets/scooter.png"

import { Vehicle } from "../types/types"

import I18n from "../utilities/I18n"
import { formatRate } from "../utilities/TextUtil"

interface UnlockingViewProps {
  vehicle: Vehicle | undefined
}

export const UnlockingView = (props: UnlockingViewProps) => {
  const { vehicle } = props
  return (
    <div className="screen">
      <div className="body-unlocking">
        <div className="title-unlocking">{I18n.t("unlocking_your_ride")}</div>
        <div className="rate-unlocking">{vehicle && formatRate(vehicle.region.rate)}</div>
        <div className="loading-unlocking">
          <MDSpinner borderSize={5} duration={2400} singleColor="#E03F23" size={280} />
          <img src={scooter} className="scooter" alt="scooter" />
        </div>
      </div>
    </div>
  )
}
