import React, { useEffect } from "react"
import { Redirect } from "react-router"
import { RouteComponentProps } from "react-router-dom"

import { AppContext } from "../AppProvider"

import { ErrorView } from "../components/ErrorView"
import { LoadingView } from "../components/LoadingView"
import { StartRideView } from "../components/StartRideView"
import { VehicleUnavailableView } from "../components/VehicleUnavailableView"

import { Trip, TripState } from "../types/types"

import { fetchVehicleData } from "../utilities/ApiUtil"
import { startTrip } from "../utilities/ApiUtil"

type VehicleParams = { vehicle_id: string }

export const StartRideScreen = ({ match }: RouteComponentProps<VehicleParams>) => {
  const {
    checkingForExistingTrip,
    error,
    setError,
    setToken,
    setTrip,
    setVehicle,
    trip,
    vehicle,
  } = React.useContext(AppContext)
  const { vehicle_id } = match.params

  useEffect(() => {
    if (!vehicle && !error) {
      fetchVehicleData(vehicle_id, setVehicle, setError)
    }
  }, [vehicle_id, vehicle, error, setError, setVehicle])

  const paymentSuccess = (token: string, email: string | undefined) => {
    startTrip(
      email,
      vehicle_id,
      token,
      (trip: Trip, token: string) => {
        setToken(token)
        setTrip(trip)
      },
      error => {
        setError(error)
        console.log("error starting ride", error)
      }
    )
  }

  const paymentError = (error: string) => {
    setError(error)
    console.log("error getting payment token:", error)
  }

  if (error) {
    return <ErrorView error={error} />
  } else if (checkingForExistingTrip) {
    return <LoadingView />
  } else if (trip && trip.state !== TripState.completed) {
    return <Redirect to={"/trip"} />
  } else if (vehicle) {
    if (vehicle.available_for_rent) {
      return (
        <StartRideView vehicle={vehicle} tokenSuccess={paymentSuccess} tokenError={paymentError} />
      )
    } else {
      return <VehicleUnavailableView />
    }
  } else {
    return <LoadingView />
  }
}
