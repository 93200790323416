import * as React from "react"
import {
  injectStripe,
  ReactStripeElements,
  PaymentRequestButtonElement,
} from "react-stripe-elements"

type Props = {
  authAmountCents: number
  lineItems: string[]
  canMakePayment: boolean | undefined
  setCanMakePayment: (canMakePayment: boolean) => void
  tokenSuccess: (token: string, email: string | undefined) => void
  tokenError: (error: string) => void
}

type State = {
  paymentRequest: stripe.paymentRequest.StripePaymentRequest
}

class _PaymentRequestButton extends React.Component<
  Props & ReactStripeElements.InjectedStripeProps,
  State
> {
  constructor(props: Props & ReactStripeElements.InjectedStripeProps) {
    super(props)

    if (props.stripe) {
      const paymentRequest = props.stripe.paymentRequest({
        country: "US",
        currency: "usd",
        displayItems: this.props.lineItems.map(label => ({
          label,
          amount: this.props.authAmountCents,
          pending: true,
        })),
        total: {
          label: "Spin",
          amount: this.props.authAmountCents,
          pending: true,
        },
        requestPayerEmail: true,
      })

      paymentRequest.on("token", ({ complete, token, ...data }) => {
        console.log("Received Stripe token: ", token)
        console.log("Received customer information: ", data.payerEmail)
        complete("success")
        this.props.tokenSuccess(token.id, data.payerEmail)
      })

      paymentRequest.canMakePayment().then(result => {
        this.props.setCanMakePayment(!!result)
      })

      this.state = {
        paymentRequest,
      }
    }
  }

  render() {
    return this.props.canMakePayment ? (
      <PaymentRequestButtonElement
        paymentRequest={this.state.paymentRequest}
        className="PaymentRequestButton"
        style={{
          // For more details on how to style the Payment Request Button, see:
          // https://stripe.com/docs/elements/payment-request-button#styling-the-element
          paymentRequestButton: {
            theme: "dark",
            height: "48px",
          },
        }}
      />
    ) : null
  }
}
export const PaymentRequestButton = injectStripe(_PaymentRequestButton)
