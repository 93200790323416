import React, { useState } from "react"
import MDSpinner from "react-md-spinner"

import I18n from "../utilities/I18n"

type EndRideProps = {
  stopRide: () => Promise<void>
}

export const EndRideButton = (props: EndRideProps) => {
  const [endingRide, setEndingRide] = useState(false)

  const onPress = async () => {
    setEndingRide(true)
    await props.stopRide()
    setEndingRide(false)
  }

  return (
    <button className="btn-action" disabled={endingRide} onClick={onPress}>
      {endingRide ? (
        <MDSpinner borderSize={3} duration={2400} singleColor="#E03F23" size={20} />
      ) : (
        I18n.t("end_ride")
      )}
    </button>
  )
}
