import React, { useState } from "react"

import battery_100 from "../assets/battery100.png"
import battery_80 from "../assets/battery80.png"
import battery_60 from "../assets/battery60.png"
import battery_40 from "../assets/battery40.png"
import battery_20 from "../assets/battery20.png"
import locked_scooter from "../assets/locked_scooter.png"
import marble_background from "../assets/marble_background.png"

import { DownloadAppButton } from "../components/DownloadAppButton"
import { PaymentButton } from "../components/PaymentButton"

import { Vehicle } from "../types/types"

import I18n from "../utilities/I18n"
import { formatRate, formatLineItemRates } from "../utilities/TextUtil"

interface StartRideViewProps {
  vehicle: Vehicle
  tokenSuccess: (token: string, email: string | undefined) => void
  tokenError: (error: string) => void
}

export const StartRideView = (props: StartRideViewProps) => {
  const { vehicle } = props
  const [canMakePayment, setCanMakePayment] = useState<boolean | undefined>(undefined)
  return (
    <div className="screen">
      <div className="body relative">
        <img src={marble_background} className="background absolute-fill" alt="marble_background" />
        {renderVehicleInfo(vehicle)}
      </div>
      <div className="footer">
        {canMakePayment || canMakePayment === undefined ? (
          <PaymentButton
            amount={vehicle.region.maximum_charge_amount}
            lineItems={formatLineItemRates(vehicle.region.rate)}
            canMakePayment={canMakePayment}
            setCanMakePayment={setCanMakePayment}
            tokenSuccess={props.tokenSuccess}
            tokenError={props.tokenError}
          />
        ) : (
          <div className="footer-col">
            <p className="payment-text">{I18n.t("invalid_payment_method")}</p>
            <DownloadAppButton />
          </div>
        )}
      </div>
    </div>
  )
}

const renderVehicleInfo = (vehicle: Vehicle) => {
  return (
    <div className="trip-start-info">
      <div className="column">
        <div className="scooter-image-container">
          <img src={locked_scooter} className="scooter-image" alt="locked_scooter" />
        </div>
        <div className="vehicle-info">
          <div>{vehicle.id}</div>
          <div className="battery-info">
            {renderBattery(vehicle.batt_percentage)}
            <span style={{ lineHeight: "23px" }}>{vehicle.batt_percentage}%</span>
          </div>
        </div>
        <div className="price">{formatRate(vehicle.region.rate)}</div>
      </div>
    </div>
  )
}

const renderBattery = (batt_percentage: number) => {
  let src = battery_20

  if (batt_percentage > 80) {
    src = battery_100
  } else if (batt_percentage > 60) {
    src = battery_80
  } else if (batt_percentage > 40) {
    src = battery_60
  } else if (batt_percentage > 20) {
    src = battery_40
  }

  return <img src={src} className="battery-bar" alt="battery_bar" />
}
