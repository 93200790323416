import * as React from "react"
import "./styles/App.css"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { AppProvider } from "./AppProvider"
import { SpinHeader } from "./components/SpinHeader"

import { StartRideScreen } from "./screens/StartRideScreen"
import { TripScreen } from "./screens/TripScreen"
import { ReceiptScreen } from "./screens/ReceiptScreen"

export const App = () => {
  return (
    <AppProvider>
      <BrowserRouter>
        <SpinHeader />
        <Switch>
          <Route path="/vehicles/:vehicle_id(\d{7})" component={StartRideScreen} />
          <Route path="/trip" component={TripScreen} />
          <Route path="/receipt" component={ReceiptScreen} />
          <RedirectToHome />
        </Switch>
      </BrowserRouter>
    </AppProvider>
  )
}

const RedirectToHome = () => {
  return (
    <Route component={() => {
        window.location.href = "https://spin.app"
        return null
      }}
    />
  )
}
