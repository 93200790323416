import React, { useEffect, useState } from "react"
import { StripeProvider } from "react-stripe-elements"

import { Trip, Vehicle } from "./types/types"

import { currentTrip } from "./utilities/ApiUtil"
import { getTokenFromStorage, getTripIDFromStorage, missing_token, missing_trip_id, setTokenInStorage, setTripIDInStorage } from "./utilities/StorageUtil"

export interface AppContextProps {
  checkingForExistingTrip: boolean
  error: string
  setError: (error: string) => void
  setToken: (token: string) => void
  setTrip: (trip: Trip) => void
  setVehicle: (vehicle: Vehicle) => void
  token: string
  tripID: string
  trip: Trip | undefined
  vehicle: Vehicle | undefined
}

export const createDefaultState = (): AppContextProps => ({
  checkingForExistingTrip: true,
  error: "",
  setError: () => {},
  setToken: () => {},
  setTrip: () => {},
  setVehicle: () => {},
  token: "",
  tripID: "",
  trip: undefined,
  vehicle: undefined,
})

export const AppContext = React.createContext(createDefaultState())

export const AppProvider: React.StatelessComponent = props => {
  const [checkingForExistingTrip, setCheckingForExistingTrip] = useState(true)
  const [error, setError] = useState("")
  const [token, setToken] = useState("")
  const [trip, setTrip] = useState<Trip | undefined>(undefined)
  const [tripID, setTripID] = useState("")
  const stripeToken = process.env.REACT_APP_STRIPE_TOKEN || "pk_test_NOOOOPE"
  const [vehicle, setVehicle] = useState<Vehicle | undefined>(undefined)
  const hasValidToken = token && token !== missing_token
  const hasValidTripId = tripID && tripID !== missing_trip_id

  const setTokenWithStorage = (token: string) => {
    setTokenInStorage(token)
    setToken(token)
  }

  const setTripWithStorage = (trip: Trip) => {
    setTrip(trip)
    setTripIDInStorage(trip.id)
    setTripID(trip.id)
  }

  useEffect(() => {
    if (!token && !tripID) {
      setToken(getTokenFromStorage())
      setTripID(getTripIDFromStorage())
    }
  }, [token, tripID])

  useEffect(() => {
    if (checkingForExistingTrip && (token === missing_token || tripID === missing_trip_id)) {
      setCheckingForExistingTrip(false)
    }
  }, [checkingForExistingTrip, token, tripID])

  useEffect(() => {
    if (hasValidToken && hasValidTripId && !trip && !error) {
      currentTrip(tripID, token, (trip: Trip) => {
        setCheckingForExistingTrip(false)
        setTrip(trip)
      }, (error: string) => {
        setCheckingForExistingTrip(false)
        setError(error)
      })
    }
  }, [hasValidToken, hasValidTripId, trip, error, token, tripID])

  return (
    <AppContext.Provider value={{ checkingForExistingTrip, error, setError, setToken: setTokenWithStorage, setTrip: setTripWithStorage, setVehicle, token, trip, tripID, vehicle }}>
      <StripeProvider apiKey={stripeToken}>{props.children}</StripeProvider>
    </AppContext.Provider>
  )
}
