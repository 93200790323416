import React from "react"

interface ErrorProps {
  error: string
}

export const ErrorView = (props: ErrorProps) => {
  return (
    <div className="screen">
      <div className="body">{`An error has occurred: ${props.error}`}</div>
    </div>
  )
}
