import React, { useEffect, useState } from "react"
import { CarouselProvider, Dot, Slider, Slide } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"

import no_parking from "../assets/no_parking.png"
import no_riding from "../assets/no_riding.png"
import marble_background from "../assets/marble_background.png"

import { EndRideButton } from "../components/EndRideButton"

import { ParkingScreen, Trip } from "../types/types"

import { formatMilliseconds } from "../utilities/TextUtil"
import I18n from "../utilities/I18n"

interface TripProps {
  parking_screens?: ReadonlyArray<ParkingScreen>
  stopRide: () => Promise<void>
  trip: Trip
}

export const CurrentTripView = (props: TripProps) => {
  const { parking_screens, stopRide, trip } = props
  const { in_no_parking_zone, in_no_ride_zone, started_at } = trip
  const [tripDuration, setTripDuration] = useState(calcDuration(started_at))
  const showBanner = in_no_ride_zone || in_no_parking_zone

  useEffect(() => {
    const interval = setInterval(() => {
      setTripDuration(calcDuration(started_at))
    }, 1000)
    return () => clearInterval(interval)
  }, [started_at])

  return (
    <div className="screen">
      <div className="body">
        <div className="timer-container">
          <img src={marble_background} className="scooter-photo" alt="marble_background" />
          <div className="timer" style={{ marginTop: showBanner ? 83 : 33 }}>
            <div className="timer-title">{I18n.t("ride_in_progress")}</div>
            <div className="trip-duration">{formatMilliseconds(tripDuration)}</div>
          </div>
          {showBanner && renderWarningBanner(in_no_ride_zone)}
        </div>
        {parking_screens && (
          <CarouselProvider
            className="rules-container"
            naturalSlideWidth={100}
            naturalSlideHeight={100}
            totalSlides={parking_screens.length}
          >
            <Slider className="rules-slider">{parking_screens.map(renderRuleSlide)}</Slider>
            <div className="carousel__dot-group">{parking_screens.map(renderDot)}</div>
          </CarouselProvider>
        )}
      </div>
      {!in_no_parking_zone && (
        <div className="footer">
          <EndRideButton stopRide={stopRide} />
        </div>
      )}
    </div>
  )
}

const renderWarningBanner = (in_no_ride_zone: boolean) => {
  const image = in_no_ride_zone ? no_riding : no_parking
  return (
    <div className="banner-alert" onClick={contactSupport}>
      <img src={image} className="banner-icon" alt={image} />
      <div className="banner-text">
        {in_no_ride_zone ? I18n.t("do_not_ride") : I18n.t("do_not_park")}
        <span style={{ textDecoration: "underline" }}>{I18n.t("contact_support")}</span>
      </div>
    </div>
  )
}

const contactSupport = () => {
  window.location.href = "https://spinbikeshare.zendesk.com/hc/en-us"
}

const renderRuleSlide = (rule: ParkingScreen, index: number) => {
  return (
    <Slide className="rules-slide" index={index}>
      <div className="rule-title">{rule.title}</div>
      <div className="rule-text">{rule.text1}</div>
    </Slide>
  )
}

const renderDot = (_: ParkingScreen, index: number) => {
  return (
    <Dot className="circle" slide={index}>
      {""}
    </Dot>
  )
}

const calcDuration = (started_at: number) => Date.now() - started_at * 1000
