export type ParkingScreen = {
  always_shown: boolean
  title: string
  image: string
  text1: string
  text2: string
}

export type Rate = {
  base_rate: number
  rate_period: number
  effective_rate: number
  discounts_disabled: boolean
  base_discounts_enabled: boolean
}

export type Region = {
  name: string
  id: number
  currency: string
  maximum_charge_amount: number
  parking_screens: [ParkingScreen]
  unavailable_before: number
  unavailable_after: number
  rate: Rate
  is_enabled: boolean
}

export type Vehicle = {
  id: number
  batt_percentage: number
  region_id: number
  available_for_rent: boolean
  region: Region
}

export type LedgerItem = {
  amount: number
  debit: boolean
  description: string
  id: string
  type: LedgerItemType
  user_money_payment: boolean
}

export enum LedgerItemType {
  TRIP_COST = "trip_cost",
  USER_CREDIT = "user_credit",
  REBALANCE_ITEM = "TripLedgerItem::RebalanceItem",
  BASE = "base",
  DISCOUNT = "discount",
  REFUND = "refund",
}

export enum TripState {
  pending = "pending",
  in_progress = "in_progress",
  completed = "completed",
  timed_out = "timed_out",
}

export type Trip = {
  ended_at: number
  id: string
  in_no_parking_zone: boolean
  in_no_ride_zone: boolean
  ledger: [LedgerItem]
  locked: boolean
  started_at: number
  token: string
  user_id: string
  vehicle_id: string
  state: TripState
  user_money_paid: number
}
