import React from "react"

import marble_background from "../assets/marble_background.png"

import { DownloadAppButton } from "../components/DownloadAppButton"

import { Colors } from "../styles/Colors"

import { LedgerItem, LedgerItemType, Trip } from "../types/types"

import I18n from "../utilities/I18n"
import { formatMoney } from "../utilities/TextUtil"

interface ReceiptProps {
  trip: Trip
}

export const ReceiptView = (props: ReceiptProps) => {
  const { trip } = props
  return (
    <div className="screen">
      <div className="body">
        <div className="receipt-header">
          <img src={marble_background} className="background" alt="marble_background" />
          <div className="receipt-header-title">{I18n.t("thank_you_for_riding")}</div>
        </div>
        <div className="receipt-body">
          <div className="receipt-trip-number">{"#" + trip.vehicle_id}</div>
          <TripStart />
          <TripEnd />
          <div className="receipt-divider" />
          {debitItems(trip.ledger).map(renderLedgerItem)}
          <div className="receipt-divider" />
          {creditItems(trip.ledger).map(renderLedgerItem)}
          <div className="receipt-divider" />
          {renderTripCostTotal(trip.user_money_paid)}
        </div>
      </div>
      <DownloadAppButton />
    </div>
  )
}

const debitItems = (ledger: [LedgerItem]) => {
  return ledger
    .filter(item => !item.user_money_payment && item.type !== LedgerItemType.REFUND)
    .sort((itemA, itemB) => {
      if (itemA.type !== LedgerItemType.TRIP_COST && itemB.type === LedgerItemType.TRIP_COST)
        return 1
      if (itemA.type === LedgerItemType.TRIP_COST && itemB.type !== LedgerItemType.TRIP_COST)
        return -1
      return 0
    })
}

const creditItems = (ledger: [LedgerItem]) => {
  return ledger
    .filter(item => item.user_money_payment || item.type === LedgerItemType.REFUND)
    .sort((itemA, itemB) => {
      if (itemA.type === LedgerItemType.REFUND && itemB.type !== LedgerItemType.REFUND) return 1
      if (itemA.type !== LedgerItemType.REFUND && itemB.type === LedgerItemType.REFUND) return -1
      return 0
    })
}

const renderLedgerItem = (ledgerItem: LedgerItem) => {
  const { amount, debit, description, type, user_money_payment, id } = ledgerItem
  const formattedMoney = formatMoney(amount)
  const formattedAmount = debit || user_money_payment ? formattedMoney : `(${formattedMoney})`
  const color = type === LedgerItemType.USER_CREDIT ? Colors.green : "black"

  return (
    <div key={id} className="receipt-row">
      <div className="receipt-text">{description}</div>
      <div className="receipt-text" style={{ color }}>
        {formattedAmount}
      </div>
    </div>
  )
}

const renderTripCostTotal = (cost: number) => {
  return (
    <div className="receipt-row">
      <div className="receipt-total">{I18n.t("total")}</div>
      <div className="receipt-total" style={{ color: "#FF5436" }}>
        {formatMoney(cost)}
      </div>
    </div>
  )
}

const TripEnd = () => {
  return (
    <div className="receipt-row">
      <div className="receipt-text">{"23 1st St, San Francisco, CA 94105, USA"}</div>
      <div className="receipt-total" style={{ color: "#E90000" }}>
        {"2:45 pm"}
      </div>
    </div>
  )
}

const TripStart = () => {
  return (
    <div className="receipt-row">
      <div className="receipt-text">{"23 1st St, San Francisco, CA 94105, USA"}</div>
      <div className="receipt-total" style={{ color: "#36CF6F" }}>
        {"2:45 pm"}
      </div>
    </div>
  )
}
