import React from "react"
import MDSpinner from "react-md-spinner"

export const LoadingView = () => {
  return (
    <div className="screen">
      <div className="body">
        <MDSpinner borderSize={5} duration={2400} singleColor="#E03F23" size={280} />
      </div>
    </div>
  )
}
