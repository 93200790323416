import * as React from "react"
import { Redirect } from "react-router"

import { AppContext } from "../AppProvider"

import { ErrorView } from "../components/ErrorView"
import { LoadingView } from "../components/LoadingView"
import { ReceiptView } from "../components/ReceiptView"

import { TripState } from "../types/types"

export const ReceiptScreen = () => {
  const { checkingForExistingTrip, error, trip } = React.useContext(AppContext)

  if (error) {
    return <ErrorView error={"error with receipt"} />
  } else if (trip) {
    if (trip.state !== TripState.completed) {
      return <Redirect to={"/trip"} />
    } else {
      return <ReceiptView trip={trip} />
    }
  } else if (checkingForExistingTrip) {
    return <LoadingView />
  } else {
    return <ErrorView error={"no trip found"} />
  }
}
