import React, { useEffect } from "react"
import { Redirect } from "react-router"

import { AppContext } from "../AppProvider"

import { CurrentTripView } from "../components/CurrentTripView"
import { ErrorView } from "../components/ErrorView"
import { FailedTripView } from "../components/FailedTripView"
import { LoadingView } from "../components/LoadingView"
import { UnlockingView } from "../components/UnlockingView"

import { TripState } from "../types/types"

import { currentTrip, endTrip, fetchVehicleData } from "../utilities/ApiUtil"

export const TripScreen = () => {
  const {
    checkingForExistingTrip,
    error,
    setError,
    setTrip,
    setVehicle,
    token,
    trip,
    tripID,
    vehicle,
  } = React.useContext(AppContext)

  useEffect(() => {
    if (!error) {
      const interval = setInterval(() => {
        if (tripID && token) {
          currentTrip(tripID, token, setTrip, setError)
        }
      }, 5000)
      return () => clearInterval(interval)
    }
  }, [error, tripID, token, setError, setTrip])

  useEffect(() => {
    if (trip && trip.vehicle_id && !vehicle && !error) {
      fetchVehicleData(trip.vehicle_id, setVehicle, setError)
    }
  })

  const stopRide = async () => {
    if (tripID && token) {
      await endTrip(tripID, token, setTrip, setError)
    }
  }

  if (error) {
    return <ErrorView error={error} />
  } else if (trip) {
    if (trip.state === TripState.timed_out) {
      return <FailedTripView />
    } else if (trip.state === TripState.completed) {
      return <Redirect to={"/receipt"} />
    } else if (trip.state === TripState.in_progress) {
      return (
        <CurrentTripView
          parking_screens={vehicle && vehicle.region.parking_screens}
          trip={trip}
          stopRide={stopRide}
        />
      )
    } else if (trip.state === TripState.pending) {
      return <UnlockingView vehicle={vehicle} />
    } else {
      return <ErrorView error={error} />
    }
  } else if (checkingForExistingTrip) {
    return <LoadingView />
  } else {
    return <ErrorView error={"no trip found"} />
  }
}
